import { AddOnType } from './AddOnDto';
import { BillingInfoDto } from './BillingInfoDto';
import { PackageTier } from './PackageDto';
import { OrganizationCurrency } from './OrganizationDto';

export interface UpgradePricingModel {
  organizationId: string;
  upgradeTransactionId?: string | null;
  package: UpgradePackageDto | null;
  addOns: UpgradeAddOnDto[];
  billingInfo?: BillingInfoDto | null;
  termLengthInYears: number | null;
  maxActiveEvents: number | null;
  renew: boolean;
}

interface UpgradePackageDto {
  id: string;
  cost: number;
  fee: number;
}

interface UpgradeAddOnDto {
  id: string;
  cost: number;
}

export interface GetUpgradeTransactionPricingDto {
  upgradeTransactionId: string;
  organizationId: string;
  billingInfo: BillingInfoDto;
}

export enum UpgradeItemType {
  CurrentPlanCredit = 'CurrentPlanCredit',
  MultiYearDiscount = 'MultiYearDiscount',
  MultiEventDiscount = 'MultiEventDiscount',
  IncludedInPackage = 'IncludedInPackage',
  Charge = 'Charge',
}

export interface UpgradeItemDto {
  addOnType: AddOnType | null;
  packageTier: PackageTier | null;
  cost: number;
  gst: number;
  qst: number;
  hst: number;
  total: number;
  unitCost: number;
  type: UpgradeItemType;
}

export interface UpgradePricingResponseDto {
  items: UpgradeItemDto[];
  subtotalBeforeRebates: number;
  subtotalBeforeCredit: number;
  subtotal: number;
  gst: number;
  qst: number;
  hst: number;
  total: number;
  currency: OrganizationCurrency;
  termLengthInYears: number;
  maxActiveEvents: number;
  planStartDate: string;
  planEndDate: string;
  nextInvoiceDate: string | null;
  nextInvoiceTotal: number;
}
