
import { Component, Vue, Prop, Ref, Emit } from 'nuxt-property-decorator';
import UserProfilePicture from '../users/UserProfilePicture.vue';
import { isMemberOfAnyOrganization } from '../../services/fourwaves/helpers';
import ContextualMenu from './ContextualMenu.vue';
import { TranslateResult } from 'vue-i18n';

interface UserDropdownOption {
  label: TranslateResult;
  externalUrl: string;
  internalUrl: { name: string };
  icon: string;
  dataTestId: string;
}

@Component({
  components: {
    UserProfilePicture,
    ContextualMenu,
  },
})
export default class UserDropdown extends Vue {
  @Prop(Boolean) readonly externalUse!: boolean;

  @Ref() contextualMenu?: ContextualMenu;

  @Emit() openSplashScreen() {}

  userDropdownActive = false;

  get hasOrganizations(): boolean {
    return this.$auth.user ? isMemberOfAnyOrganization(this.$auth.user) : false;
  }

  get menuItems(): Record<string, UserDropdownOption> {
    const menuItems = {
      participant: {
        label: this.$t('shared.global.navigation.participant'),
        externalUrl: `${this.$config.API_URL}/event-redirect/participant-dashboard`,
        internalUrl: { name: 'user-dashboard-participant-index-events' },
        icon: 'name-tag',
        dataTestId: 'participant-dashboard',
      },
      reviewer: {
        label: this.$t('shared.global.navigation.reviewer'),
        externalUrl: `${this.$config.API_URL}/event-redirect/reviewer-dashboard`,
        internalUrl: { name: 'user-dashboard-reviewer' },
        icon: 'reviewer',
        dataTestId: 'reviewer-dashboard',
      },
    };

    const organizer = {
      label: this.$t('shared.global.navigation.organizer'),
      externalUrl: `${this.$config.API_URL}/event-redirect/organizations`,
      internalUrl: { name: 'organizations' },
      icon: 'tools',
      dataTestId: 'organizer-dashboard',
    };

    return this.hasOrganizations ? { organizer, ...menuItems } : menuItems;
  }

  get profilePage(): UserDropdownOption {
    return {
      label: this.$t('shared.global.navigation.profile'),
      externalUrl: `${this.$config.API_URL}/event-redirect/account`,
      internalUrl: { name: 'account-profile' },
      icon: 'user',
      dataTestId: 'user-profile',
    };
  }

  get fullName(): string | null {
    return this.$auth.user?.firstName && this.$auth.user?.lastName
      ? `${this.$auth.user.firstName} ${this.$auth.user.lastName}`
      : null;
  }

  get email(): string {
    return this.$auth.user.email;
  }

  public closeTray() {
    this.contextualMenu?.closeTray();
  }

  public async logout() {
    await this.$auth.logout();
    this.closeTray();
    window.location.reload();
  }
}
