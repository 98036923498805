
import { Component, Emit, Prop, Watch, Inject, Vue } from 'nuxt-property-decorator';
import { CancelTokenSource } from 'axios';
import { Validator } from 'vee-validate';
import type { UploadFileType, UploadFileResult, ValidationError } from '../../../../services';
import { isSuccess, isValidationError } from '../../../../services';
import type { InputFileListEntry, InputFileMeta } from '../../../../types';
import { InputFileType, InputFileUploadStatus, InputFileInvalidMessage } from '../../../../types';
import InputFileProgress from './InputFileProgress.vue';
import { TranslateResult } from 'vue-i18n';

const fileNameAndExtensionGroupRegex = /(.+(?=\.[^.]+$))\.([^.]+)$/;
const fileExtensionGroupRegex = /.+(?=\.[^.]+$)\.([^.]+)$/;

@Component({
  components: {
    InputFileProgress,
  },
})
export default class InputFileEntry extends Vue {
  @Prop({ type: String, required: true }) readonly uploadType!: UploadFileType;
  @Prop({ type: Object, required: true }) readonly entry!: InputFileListEntry;
  @Prop({ type: String, required: true }) readonly uploadIdentifier!: string;
  @Prop({ type: Number, default: null }) readonly maxSizeBytes!: number | null;
  @Prop(Boolean) readonly draggable!: boolean;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly readonly!: boolean;

  @Inject() readonly $validator!: Validator;

  readonly InputFileUploadStatus = InputFileUploadStatus;

  readonly testIds = {
    delete: 'delete-file-button',
    entry: 'input-file-entry',
    errorIcon: 'file-entry-error-icon',
    errorMessage: 'file-entry-error',
    handle: 'file-entry-handle',
    icon: 'file-entry-document-icon',
    link: 'file-entry-link',
    name: 'file-entry-name',
    preview: 'file-entry-preview',
    progress: 'file-entry-progress',
  };

  status = InputFileUploadStatus.Pending;
  fileMeta: InputFileMeta | null = null;
  progress: number | null = null;
  showPreviewImage = false;
  cancelTokenSource: CancelTokenSource | null = null;

  get classNames() {
    return {
      '-complete': this.uploadHasComplete,
      '-error': this.uploadHasError,
    };
  }

  get deleteAriaLabel(): TranslateResult {
    if (this.fileMeta) {
      return this.$t('shared.inputs.file.delete_file', this.fileMeta);
    }
    return '';
  }

  get uploadHasComplete(): boolean {
    return this.status === InputFileUploadStatus.Complete || this.status === InputFileUploadStatus.Existing;
  }

  get uploadHasError(): boolean {
    return Boolean(this.invalidFileMessage || this.status === InputFileUploadStatus.Error);
  }

  get uploadErrorMessage(): TranslateResult {
    if (this.invalidFileMessage) {
      return this.invalidFileMessage;
    }
    if (this.$device.laptop) {
      return this.$t('shared.inputs.file.upload_failed');
    }
    return this.$t('shared.inputs.file.upload_failed_mobile');
  }

  get filePreviewUrl(): string | null {
    const hasNoMedia = !this.entry.url && !this.entry.file;

    if (this.invalidFileMessage || hasNoMedia || this.fileMeta?.type !== InputFileType.Image) {
      return null;
    }

    if (this.entry.file) {
      return URL.createObjectURL(this.entry.file);
    }

    if (this.entry.url) {
      try {
        const url = new URL(this.entry.url);
        url.searchParams.append('w', '512');
        return url.toString();
      } catch {
        return this.entry.url;
      }
    }

    return null;
  }

  get fileIcon() {
    if (!this.fileMeta || this.fileMeta?.type === InputFileType.Other) return 'page';
    return this.fileMeta.type === InputFileType.Image ? 'image' : 'film';
  }

  get truncatedFileName() {
    const fileName = this.fileMeta?.fileName || '';
    const [, name, extension] = fileName.match(fileNameAndExtensionGroupRegex) || [];

    return name
      ? { start: name.slice(0, -4), end: `${name.slice(-4)}.${extension}` }
      : { start: fileName.slice(0, -4), end: fileName.slice(-4) };
  }

  get isDraggable() {
    return !this.invalidFileMessage && this.draggable && this.multiple && !this.readonly;
  }

  get showProgress() {
    return this.status !== InputFileUploadStatus.Error && this.status !== InputFileUploadStatus.Existing;
  }

  get invalidFileMessage() {
    const { file, invalidFile, serverErrorCode } = this.entry;
    if (!file || !invalidFile) return null;

    if (invalidFile === InputFileInvalidMessage.Type) {
      const [, extension = ''] = file.name.match(fileExtensionGroupRegex) || [];
      return this.$t('shared.inputs.file.invalid_file_type', { extension: `.${extension}` });
    } else if (invalidFile === InputFileInvalidMessage.Size && this.maxSizeBytes) {
      return this.$t('shared.inputs.file.invalid_file_size', { maxSize: this.formatFileSize(this.maxSizeBytes) });
    }

    const hasServerMessage =
      invalidFile === InputFileInvalidMessage.Server &&
      serverErrorCode &&
      this.$te(`shared.error.server_validation.${serverErrorCode}`);

    return hasServerMessage
      ? this.$t(`shared.error.server_validation.${serverErrorCode}`, this.fileMeta || {})
      : this.$t('shared.inputs.file.invalid_file');
  }

  @Emit('upload-start')
  emitUploadStart() {
    return this.entry.key;
  }

  @Emit('upload-end')
  emitUploadEnd() {
    return this.entry.key;
  }

  @Emit('upload-failed')
  emitUploadFailed(errorCode: string | null) {
    return this.entry.key || errorCode;
  }

  @Emit('complete')
  emitComplete(result: UploadFileResult & { key: string }) {
    return result;
  }

  @Emit('delete')
  emitDelete() {
    this.cancelTokenSource?.cancel();
    this.cancelTokenSource = null;
    return this.entry;
  }

  @Watch('entry', { immediate: true })
  onEntryChanged() {
    const { size, type, originalFileName, invalidFile } = this.entry;
    const [, , extension] = originalFileName.match(fileNameAndExtensionGroupRegex) || [];

    this.fileMeta = {
      extension,
      fileName: originalFileName,
      size: this.formatFileSize(size),
      type: this.parseFileType(type),
    };

    if (invalidFile) this.status = InputFileUploadStatus.Error;
    else if (this.entry.resourceId && !this.entry.file) this.status = InputFileUploadStatus.Existing;
    else if (!this.entry.resourceId && this.entry.file) this.uploadFile(this.entry.file);
  }

  created() {
    if (this.entry.resourceId && this.entry.file) this.status = InputFileUploadStatus.Complete;
  }

  public async uploadFile(file: File) {
    this.emitUploadStart();
    this.cancelTokenSource = this.$axios.CancelToken.source();

    const result = await this.$api.uploadFile(
      { file, id: this.uploadIdentifier, type: this.uploadType },
      { onUploadProgress: this.onProgress, cancelToken: this.cancelTokenSource.token },
    );

    if (!isSuccess(result)) {
      this.status = InputFileUploadStatus.Error;
      const code = isValidationError(result) ? this.getServerValidationErrorCode(result) : null;
      this.emitUploadFailed(code);
    } else {
      this.status = InputFileUploadStatus.Complete;
      this.emitComplete({ ...result, key: this.entry.key });
    }

    this.cancelTokenSource = null;
    this.emitUploadEnd();
  }

  public getServerValidationErrorCode({ errors }: ValidationError) {
    const { Id, File, Type, ...fields } = errors;
    const [field] = Object.values(fields);
    const [{ code }] = field || [];
    return code?.toLowerCase();
  }

  public parseFileType(mimeType: string | null = '') {
    if (mimeType?.startsWith(InputFileType.Image)) return InputFileType.Image;
    return mimeType?.startsWith(InputFileType.Video) ? InputFileType.Video : InputFileType.Other;
  }

  public onProgress(progress: ProgressEvent) {
    if (!progress.lengthComputable) return;
    if (this.status === InputFileUploadStatus.Pending) this.status = InputFileUploadStatus.Uploading;
    this.progress = progress.loaded / progress.total;
  }

  public imgLoadHandler() {
    this.showPreviewImage = true;
  }

  public imgErrorHandler($event: Event) {
    const { target } = $event;
    this.showPreviewImage = false;
    if (target instanceof HTMLImageElement) target.parentNode?.removeChild(target);
  }
}
