import Vue from 'vue';
import VueRouter from 'vue-router';
import { IVueI18n } from 'vue-i18n';

// Handle anchor within the same event as internal route.
// This is exported for testing purpose.
export const anchorInterceptor = function ({
  event,
  localeCodes,
  router,
  origin,
}: {
  event: MouseEvent;
  localeCodes: readonly string[];
  router: VueRouter;
  origin: string;
}): void {
  const { button, ctrlKey, shiftKey, altKey, metaKey, target } = event;

  if (button !== 0 || ctrlKey || shiftKey || altKey || metaKey || !(target instanceof HTMLAnchorElement)) return;

  if (target.closest('[data-no-anchor-interceptor]')) return;

  const { href } = target;
  const { slug } = router.currentRoute.params;
  const eventBaseHref = new RegExp(`^${origin}(?:/(?:${localeCodes.join('|')}))?/${slug}/`);

  if (!slug || !eventBaseHref.test(href)) return;

  event.preventDefault();
  const internalRoute = href.replace(origin, '');
  router.push(internalRoute);
};

// Plugin used to handle anchor within the same event as internal route.
// This plugin must be executed on `client` side only.
export default (context: { app: Vue & { i18n: IVueI18n; router: VueRouter } }) => {
  const { origin } = window.location;
  const { i18n, router } = context.app;
  const { localeCodes } = i18n;

  document.addEventListener(
    'click',
    (event: MouseEvent) => {
      anchorInterceptor({ event, localeCodes, router, origin });
    },
    {
      // Using the capturing phase because it should never be stopped.
      // There is a higher risk of being stopped if using the bubbling phase.
      capture: true,
    },
  );
};
