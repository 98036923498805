import { MarketSegment } from '../../services/fourwaves/models';

export default {
  create_org: {
    title: 'Les organisations sont arrivées!',
    description:
      "Les organisations deviendront le nouveau pôle autour duquel s'articuleront vos événements, avec un éventail de nouveaux avantages et outils à votre disposition. Commencez par créer et paramétrer votre organisation, et nous nous occuperons du reste.",
    modal_title: 'Créer une organisation',
    learn_more_link: 'https://fourwaves.com/fr/blogue/introducing-organizations/',
    maybe_later: 'Peut-être plus tard',
    create: 'Créer',
    market_segment: {
      title: "Type d'organisation",
      subtitle: 'Sélectionnez le type qui correspond le plus à votre organisation.',
      [MarketSegment.UniversityGroup]: {
        label: 'Groupe universitaire',
        subLabel: "Un bureau ou département étudiant organisant des événements pour les membres de l'université.",
      },
      [MarketSegment.FundedResearchNetwork]: {
        label: 'Réseau de recherche subventionné',
        subLabel: 'Un groupe financé par des fonds publics organisant des événements.',
      },
      [MarketSegment.InformalResearchNetwork]: {
        label: 'Réseau de recherche informel',
        subLabel: 'Une communauté informelle, locale ou internationale.',
      },
      [MarketSegment.Association]: {
        label: 'Association ou société établie',
        subLabel: 'Une association ou société légalement enregistrée.',
      },
      [MarketSegment.ContinuingEducation]: {
        label: 'Bureau de formation continue',
        subLabel: 'Un bureau organisant des événements de formation continue.',
      },
      [MarketSegment.Private]: {
        label: 'Privée',
        subLabel: 'Une organisation privée organisant des événements à diverses fins.',
      },
      [MarketSegment.Other]: {
        label: 'Autre',
      },
    },
    infos: {
      title: 'Information générale',
      name: "Nom de l'organisation",
      name_hint: "Soyez aussi précis que possible. Ex. Département de biologie marine de l'Université Brightwater.",
      address: 'Adresse',
      address_hint: 'Requis pour publier vos événements, mettre à niveau votre plan, etc.',
      city: 'Ville',
      zip: 'Code postal ou ZIP',
    },
    add_admins: {
      title: "Ajout d'administrateurs",
      subtitle:
        'Invitez vos collègues à rejoindre votre organisation. Un courriel sera envoyé à chacun, contenant un lien pour activer leur compte ou se connecter.',
      emails: 'Adresses courriel',
      emails_placeholder: 'colleague@something.edu',
      emails_help: 'Tapez une ou plusieurs adresses courriel.',
    },
    success_message: 'Organisation créée avec succès!',
  },
};
