
import { mixins, Component, Prop } from 'nuxt-property-decorator';
import { omit } from 'lodash-es';
import Spinner from '../Spinner.vue';
import ActionButton from './ActionButton.vue';
import ActionMixin from './ActionMixin';

@Component({
  components: {
    Spinner,
    ActionButton,
  },
})
export default class ActionSpinner extends mixins(ActionMixin) {
  @Prop(Boolean) readonly submit!: boolean;
  @Prop({ type: String, default: null }) readonly awaitMessage!: string;
  @Prop(Boolean) readonly isWaiting!: boolean;

  get isDisabled() {
    return this.isWaiting || this.disabled;
  }

  get spinnerModifiers() {
    return {
      '-destructive': this.destructive,
      '-highlight': this.highlight,
      '-dark': this.dark,
      '-xsmall': this.size === 'md' || this.size === 'lg',
      '-xxsmall': this.size === 'sm',
    };
  }

  get buttonProps() {
    return {
      ...omit(this.$props, ['awaitKey', 'awaitMessage']),
      disabled: this.isDisabled,
      iconLeading: this.isWaiting ? null : this.$props.iconLeading,
      iconTrailing: this.isWaiting ? null : this.$props.iconTrailing,
    };
  }

  get spinnerMessage() {
    if (this.awaitMessage) return this.awaitMessage;
    return this.destructive ? this.$t('shared.modal.deleting') : this.$t('shared.modal.saving');
  }
}
