
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { VueperSlides, VueperSlide } from 'vueperslides';

@Component({
  components: {
    VueperSlides,
    VueperSlide,
  },
})
export default class PresentationFigures extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly figures!: { title?: string; description?: string; url: string }[];

  isSidebarCollapsed = false;
}
