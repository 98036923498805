
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { Alignment } from '../../../types';
import { FormFieldType, FieldTypeIconMap, FileUploadWithDate } from '../../../services';

@Component({})
export default class OutputFile extends Vue {
  @Prop({ required: true }) readonly fieldType!: FormFieldType;
  @Prop({ required: true }) readonly file!: FileUploadWithDate;
  @Prop({ type: String, default: Alignment.Start }) readonly alignment!: Alignment;

  readonly iconMap = FieldTypeIconMap;

  get hasThumbnail() {
    return this.fieldType === FormFieldType.Poster;
  }

  get inlineStyle() {
    if (!this.hasThumbnail || !this.file) return {};
    return { backgroundImage: `url(${this.file.url}?fit=max&w=200)` };
  }

  get rootClassNames() {
    return `-${this.alignment}`;
  }
}
