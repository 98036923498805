
import { Prop, Component, Emit, Model, mixins } from 'nuxt-property-decorator';
import { TranslateResult } from '../../../../types/i18n';
import Collapsible from '../../../layout/Collapsible.vue';
import InputValidationMixin from '../InputValidationMixin';
import { v4 as uuid } from 'uuid';

@Component({ components: { Collapsible } })
export default class InputCheckbox extends mixins(InputValidationMixin) {
  @Prop({ type: [String, Boolean, Number], default: null }) readonly value!: string | number | null;
  @Prop({ type: String, default: null }) readonly id!: string;
  @Prop({ type: String, default: null }) readonly label!: string | TranslateResult;
  @Prop({ type: String, default: null }) readonly subLabel!: string | TranslateResult;
  @Prop({ type: String, default: null }) readonly labelId!: string;
  @Prop({ type: String, default: 'md' }) readonly size!: string;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly indeterminate!: boolean;
  @Prop(Boolean) readonly showError!: boolean;

  @Model('change', { type: [Array, Boolean], default: null }) readonly currentValue!:
    | (string | number)[]
    | boolean
    | null;

  fallbackId = uuid();

  get internalModel() {
    return this.isChecked;
  }

  set internalModel(value) {
    this.emitChange();
  }

  get isChecked(): boolean {
    return this.value !== null && Array.isArray(this.currentValue)
      ? this.currentValue.includes(this.value)
      : Boolean(this.currentValue);
  }

  get inputId(): string {
    return this.id || this.fallbackId;
  }

  get labelledById() {
    if (!this.label && !this.labelId) return null;
    return this.labelId || `${this.inputId}-label`;
  }

  get ariaChecked() {
    return this.isChecked ? 'true' : 'false';
  }

  get labelClassNames() {
    return {
      '-small': this.size === 'sm',
      '-checked': this.isChecked,
      '-indeterminate': this.indeterminate,
      '-destructive': this.showError || this.hasValidationError,
      '-disabled': this.disabled,
    };
  }

  @Emit('change')
  emitChange() {
    if (!Array.isArray(this.currentValue)) return !this.isChecked;
    return this.isChecked ? this.currentValue.filter(v => v !== this.value) : [...this.currentValue, this.value];
  }
}
