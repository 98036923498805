import { FormFieldDto } from './FormDto';
import { TranslatableField } from './TranslatableField';
import { UserDto } from './UserDto';
import { CivicAddressDto } from './BillingInfoDto';

export enum PaymentGatewayConnectionState {
  Accepted = 'accepted',
  Waiting = 'waiting',
  Refused = 'refused',
  CorrectionRequired = 'correctionrequired',
}

export enum PaymentGatewayProvider {
  Stripe = 'stripe',
  GlobalPayments = 'globalpayments',
  TouchNet = 'touchnet',
  Moneris = 'moneris',
  PayPal = 'paypal',
}

export type OrganizationCurrency = 'USD' | 'CAD' | 'EUR';

export interface OrganizationDto {
  id: string;
  name: TranslatableField;
  civicAddress: CivicAddressDto | null;
  isVerified: boolean;
  currentPlanId: string;
  activeEventCount: number;
  currency: OrganizationCurrency;
}

export interface OrganizationPaymentGatewayDto {
  id: string;
  organizationId: string;
  formId: string;
  name: TranslatableField;
  code: string;
  currency: string;
  receiptHeader: TranslatableField;
  gstBusinessNumber: string;
  qstBusinessNumber: string;
  vatBusinessNumber: string;
  pstBusinessNumber: string;

  acceptedConnectionsCount?: number;
  waitingConnectionsCount?: number;
  refusedConnectionsCount?: number;
  correctionRequiredConnectionsCount?: number;

  stripePublishableKey: string | null;
  paysafePublishableKey: string | null;
  globalPaymentsAppId: string;

  touchNetTLinkUrl: string;
  touchNetUPayUrl: string;
  touchNetUPaySiteId: number | null;

  monerisStoreId: string;
  monerisTokenizationId: string;

  paypalMerchantId: string;
}

export interface OrganizationPaymentGatewayConnectionDto {
  id: string;
  formEntryId: string;
  organizationId: string;
  organizationName: TranslatableField;
  organizationPaymentGatewayId: string;
  organizationPaymentGatewayName: TranslatableField;
  eventId: string;
  eventName: TranslatableField;
  requester: UserDto;
  decisionDate: string | null;
  decisionUser: UserDto | null;
  state: PaymentGatewayConnectionState;
  notes: string;
  creationDate: string;
  eventCosts: FormFieldDto[];
}

export interface UpdateOrganizationModel {
  id: string;
  name: string;
  civicAddress: CivicAddressDto;
}
