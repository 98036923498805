
import { Component, Prop, Ref, Watch, mixins } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import ActionSpinner from '../action/ActionSpinner.vue';
import FeaturedIcon from '../design-system/molecules/FeaturedIcon.vue';
import ModalMixin from './ModalMixin';
import { v4 as uuid } from 'uuid';

@Component({ components: { FeaturedIcon, ActionSpinner } })
export default class Modal extends mixins(ModalMixin) {
  @Prop({ type: String, default: null }) readonly icon!: string | null;
  @Prop({ type: [Boolean, String], default: false }) readonly form!: boolean | string;
  @Prop({ type: String, default: null }) readonly awaitMessage!: string;
  @Prop(Boolean) readonly destructive!: boolean;
  @Prop(Boolean) readonly scrollable!: boolean;

  @Ref() readonly hiddenHandle?: HTMLButtonElement;
  @Ref() readonly modalScroll?: HTMLElement;

  formId: string | null = null;
  simplebarInstance: SimpleBar | null = null;
  hasThirdAction = false;

  get isMainButtonSubmit() {
    return !!this.form;
  }

  @Watch('showModal')
  async onShowModalChange() {
    await this.$nextTick();
    if (this.showModal) {
      if (this.modalScroll && this.scrollable) {
        this.simplebarInstance = new SimpleBar(this.modalScroll);
        this.modalScroll?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
      }
      this.hiddenHandle?.focus();
      this.$emit('open');
    } else {
      this.$emit('close');
      this.simplebarInstance?.unMount();
      this.callBackFocusElement?.focus();
      this.callBackFocusElement = null;
    }
  }

  created() {
    this.detectSlotContent();
    if (!this.form) return;
    this.formId = typeof this.form === 'string' ? this.form : uuid();
  }

  beforeUpdate() {
    this.detectSlotContent();
  }

  protected detectSlotContent() {
    this.hasThirdAction = !!this.$slots.thirdAction?.length;
  }
}
