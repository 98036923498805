
import { Component, Vue, Ref, Emit, Prop } from 'nuxt-property-decorator';
import { isSuccess, isValidationError } from '../../services';
import { InputOption } from '../../types';
import InputText from '../design-system/input/input-text/InputText.vue';
import InputSelectCountry from '../design-system/input/input-select/InputSelectCountry.vue';
import InputRadioGroup from '../design-system/input/input-radio/InputRadioGroup.vue';
import DelimiterTextArea from '../design-system/input/input-text/DelimiterTextArea.vue';
import { CreateOrganizationModel, MarketSegment, OrganizationDto } from '../../services/fourwaves/models';
import FullPageModal from '../modals/FullPageModal.vue';
import CheckboxSelectionManager from '../design-system/organisms/CheckboxSelectionManager.vue';

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    FullPageModal,
    InputText,
    InputSelectCountry,
    InputRadioGroup,
    DelimiterTextArea,
    CheckboxSelectionManager,
  },
})
export default class OrganizationCreateModal extends Vue {
  @Prop({ type: Array, default: Array }) readonly adminOrganizations!: OrganizationDto[];

  @Ref() readonly modal?: FullPageModal;

  @Emit() close() {}
  @Emit() onOpen() {}
  @Emit()
  public organizationCreated(organizationId: string): string {
    return organizationId;
  }

  model: CreateOrganizationModel = {
    name: '',
    marketSegment: '',
    civicAddress: {
      addressLine1: '',
      city: '',
      postalCode: '',
      countryRegion: '',
      stateProvince: '',
    },
    emailAddresses: [],
  };

  stepIndex = 0;

  get marketSegmentOptions(): InputOption<MarketSegment>[] {
    return Object.values(MarketSegment).map(key => ({
      id: key,
      label: this.$t(`shared.organizations.create_org.market_segment.${key}.label`),
      subLabel:
        key !== MarketSegment.Other ? this.$t(`shared.organizations.create_org.market_segment.${key}.subLabel`) : '',
    }));
  }

  get stepsTitle() {
    return [
      this.$t('shared.organizations.create_org.market_segment.title'),
      this.$t('shared.organizations.create_org.infos.title'),
      this.$t('shared.organizations.create_org.add_admins.title'),
    ];
  }

  get subtitleCaption() {
    if (this.stepIndex === 0) return this.$t('shared.organizations.create_org.market_segment.subtitle');
    if (this.stepIndex === 2) return this.$t('shared.organizations.create_org.add_admins.subtitle');
    return '';
  }

  get isWaiting() {
    return this.$wait.is('save');
  }

  public openModal() {
    this.modal?.open();
  }

  public async handleNext() {
    const isValid = await this.$validator.validateAll();
    if (isValid) this.stepIndex++;
  }

  public handleBack() {
    this.stepIndex--;
  }

  public async handleSubmit() {
    if (!(await this.$validator.validateAll())) return;

    this.$wait.start('save');
    const result = await this.$api.createOrganization(this.model);
    if (isSuccess(result)) {
      this.organizationCreated(result.organizationId);
      this.modal?.close();
    } else if (isValidationError(result)) {
      this.feedErrorBag(result);
    }

    this.$wait.end('save');
  }

  public resetModel() {
    this.model = {
      name: '',
      marketSegment: '',
      civicAddress: {
        addressLine1: '',
        city: '',
        postalCode: '',
        countryRegion: '',
        stateProvince: '',
      },
      emailAddresses: [],
    };
  }

  public onClose() {
    this.close();
    this.stepIndex = 0;
    this.resetModel();
    this.$validator.reset();
  }
}
