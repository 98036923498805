
import { Component, Vue, Prop, Ref, namespace } from 'nuxt-property-decorator';
import { RawHtml } from '../layout';
import RoomCta from '../blocks/RoomCta.vue';
import UserActionButton from '../blocks/UserActionButton.vue';
import { TranslateResult } from '../../types/i18n';
import { LiveStreamType, PageDto, PageType, RoomDto, ScheduleDto } from '../../services';
import SessionPresentationCount from './SessionPresentationCount.vue';
import SessionTrackList from './SessionTrackList.vue';
import SessionVideoModal from './SessionVideoModal.vue';

const EventsModule = namespace('events');

@Component({
  components: { RawHtml, RoomCta, UserActionButton, SessionPresentationCount, SessionTrackList, SessionVideoModal },
})
export default class SessionSingleHeader extends Vue {
  @EventsModule.State pages!: PageDto[];
  @Prop({ required: true }) readonly liveSessions!: ScheduleDto[];
  @Prop({ required: true }) session!: ScheduleDto;
  @Prop({ required: true }) readonly currentTime!: string;
  @Prop({ required: true }) readonly timezone!: string;
  @Prop({ required: false }) room!: RoomDto;
  @Prop(Boolean) isBookmarkable!: boolean;
  @Prop(Boolean) readonly isLiveApp!: boolean;

  @Ref() videoModal?: SessionVideoModal;

  get isLive() {
    return this.session ? this.liveSessions.some(({ id }) => id === this.session.id) : false;
  }

  get isVirtual() {
    return !this.room ? false : this.room.liveStreamType !== LiveStreamType.None;
  }

  get isVps() {
    return !this.room ? false : this.room.liveStreamType === LiveStreamType.VirtualPosterSession;
  }

  get isOver() {
    if (!this.session) return false;
    const now = this.$moment.utc(this.currentTime);
    return now.isSameOrAfter(this.$moment.utc(this.session.endDate));
  }

  get hasPresentationCount() {
    return this.pages.some(({ type }) => type === PageType.Abstracts) && this.session?.presentationCount && this.isVps;
  }

  get hasRoomCta() {
    if (this.isVps) return true;
    return !!(this.isVirtual && !this.isOver);
  }

  get hasVideo() {
    return !!this.session.recordedVideo?.url;
  }

  get countDown(): TranslateResult | null {
    if (this.isOver || this.isLive) return null;
    const normalizedStartDate = this.$moment(this.session.startDate);
    const now = this.$moment(this.currentTime);
    const minute = normalizedStartDate.diff(now, 'minutes');
    if (minute > 0 && minute <= 15) return this.$tc('shared.sessions.minute_countdown', minute);
    if (minute === 0) return this.$t('shared.sessions.less_than_a_minute');
    return null;
  }

  get hasFooter() {
    return !!(this.hasRoomCta || this.hasPresentationCount || this.countDown || this.isLive || this.hasVideo);
  }

  get ariaLabel(): TranslateResult {
    return !this.session.isBookmarkedByCurrentUser
      ? `${this.$t('shared.sessions.bookmark')} ${this.transl8(this.session.title)}`
      : `${this.$t('shared.sessions.unbookmark')} ${this.transl8(this.session.title)}`;
  }

  get fullDate() {
    const sessionStartDate = this.$moment(this.session.startDate).format('ll');
    const startTime = this.$moment(this.session.startDate).format('LT');
    const endTime = this.$moment(this.session.endDate).format('LT');
    const abbrTimezone = this.$moment.tz(this.session.startDate, this.timezone).format('z');
    return `${sessionStartDate}, ${startTime} - ${endTime} ${abbrTimezone}`;
  }

  public toggleBookmark() {
    this.$emit('toggle-bookmark');
  }

  public openVideoModal() {
    if (this.videoModal) this.videoModal.openSessionVideo();
  }
}
