
import { mixins, Component, Prop } from 'nuxt-property-decorator';
import { castQueryArrays } from '../../helpers';
import FilterMixin from './FilterMixin';

@Component
export default class FilterToggle extends mixins(FilterMixin) {
  @Prop({ default: true }) readonly value!: string | boolean;
  @Prop({ required: true }) readonly label!: string;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(Boolean) readonly radio!: boolean;
  @Prop(Boolean) readonly isDefault!: boolean;
  @Prop(Boolean) readonly checkbox!: boolean;

  get queryValue(): boolean | string | (string | null)[] {
    if (this.value === true) return Boolean(this.$route.query[this.queryKey]);
    const stringFallback = this.isDefault ? this.value : '';

    return this.multiple
      ? castQueryArrays<string[]>(this.$route.query, [this.queryKey])[this.queryKey] || []
      : this.$route.query[this.queryKey] || stringFallback;
  }

  get isChecked(): boolean {
    if (typeof this.queryValue === 'boolean') return this.queryValue;
    if (!this.queryValue || !this.queryValue.length) return this.isDefault;
    return this.multiple ? this.queryValue.includes(this.value as string) : this.queryValue === this.value;
  }

  set isChecked(isChecked: boolean) {
    if (typeof this.value === 'boolean') {
      this.updateQuery(isChecked, !isChecked);
    } else if (Array.isArray(this.queryValue)) {
      const newValues = isChecked
        ? [...this.queryValue, this.value as string]
        : this.queryValue.filter(value => value !== this.value);

      this.updateQuery(newValues, !newValues.length);
    } else {
      this.updateQuery(this.value, !isChecked || this.isDefault);
    }
  }

  public onClickWrapper($event: MouseEvent) {
    const canUncheck = typeof this.queryValue !== 'string' || !this.radio;
    if (!canUncheck && this.isChecked) return $event.preventDefault();
  }
}
