import { isSuccess } from '../fourwaves/models';
import { BaseApiService } from '../fourwaves/baseApiService';
import { Activity } from './activity';
import { ActivityStore, InMemoryStore, LocalStorageStore } from './storage';
import { v4 as uuid } from 'uuid';

const FLUSH_INTERVAL = 30 * 1000; // 30 seconds
const HEARTBEATS_INTERVAL = 60 * 1000; // 1 minute

export type Origin = 'event_website' | 'dashboard' | 'live';

export type ActivityOptions = {
  eventId: string;
  reference?: string;
  isTracked?: boolean;
};

export default class UserActivitiesManager {
  api: BaseApiService;
  origin: Origin;
  storage: ActivityStore;

  flushTimer: ReturnType<typeof setInterval>;
  heartbeatTimer: ReturnType<typeof setInterval> | undefined;

  trackedActivities: Activity[] = [];

  constructor(apiService: BaseApiService, origin: Origin) {
    this.api = apiService;
    this.origin = origin;

    // initialize the storage
    this.storage = LocalStorageStore.isAvailable() ? new LocalStorageStore() : new InMemoryStore();

    // initialize the session
    if (!sessionStorage.getItem('sessionId')) {
      sessionStorage.setItem('sessionId', uuid());
    }

    // initialize intervals
    this.flushTimer = setInterval(() => this.flush(), FLUSH_INTERVAL);
    this.heartbeatTimer = undefined;

    // flush user activities when the user quits the page
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.flush();
      }
    });

    // end tracked activities when the user close his browser/tab
    window.addEventListener('beforeunload', () => this.trackedActivities.forEach(activity => this.stop(activity)));
  }

  public send(name: string, options: ActivityOptions): Activity {
    const activity: Activity = {
      id: uuid(),
      name,
      timestamp: new Date().toISOString(),
      reference: options.reference,
      origin: this.origin,
      eventId: options.eventId,
      isTracked: options.isTracked ?? false,
    };

    this.storage.add(activity);

    if (activity.isTracked) {
      this.trackedActivities.push(activity);
    }

    return activity;
  }

  public start(name: string, options: ActivityOptions): Activity {
    const activity = this.send(name, { ...options, isTracked: true });
    this.flush();

    if (!this.heartbeatTimer) {
      this.heartbeatTimer = setInterval(async () => await this.emitHeartbeat(), HEARTBEATS_INTERVAL);
    }

    return activity;
  }

  public async update(activity: Activity, additionalData: any) {
    if (!activity) {
      return;
    }

    const result = await this.api.updateUserActivity(activity.id, additionalData);
    if (isSuccess(result)) {
      const index = this.trackedActivities.findIndex(x => x.id === activity.id);
      this.trackedActivities[index].additionalData = additionalData;
    }
  }

  public stop(activity: Activity | null) {
    if (activity) {
      const url = `${this.api._client.defaults.baseURL}/api/user-activities/${activity.id}/delete`;
      this.transfer(url, null);
      const index = this.trackedActivities.findIndex(x => x.id === activity.id);
      this.trackedActivities.splice(index, 1);
    }

    if (!this.trackedActivities.length && this.heartbeatTimer !== undefined) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = undefined;
    }
  }

  public async emitHeartbeat(): Promise<void> {
    if (this.trackedActivities.length) {
      await this.api.emitHeartbeat(this.trackedActivities.map(x => x.id));
    }
  }

  public flush(): void {
    const activities = this.storage.getAll();
    if (activities.length <= 0) {
      return;
    }

    const payload = {
      sentAt: new Date().toISOString(),
      sessionId: sessionStorage.getItem('sessionId'),
      activities,
    };

    this.transfer(`${this.api._client.defaults.baseURL}/api/user-activities`, payload);
    this.storage.clearAll();
  }

  public transfer(url: string, payload: any) {
    let dispatched = false;

    if ('sendBeacon' in navigator) {
      try {
        const blob = new Blob([JSON.stringify(payload)], { type: 'application/json' });

        // sendBeacon must be binded to the navigator before passing the reference
        dispatched = navigator.sendBeacon.bind(navigator)(url, blob);
      } catch (error) {
        // do nothing on purpose
        console.warn('navigator.sendBeacon failed');
      }
    }

    // There are two cases when dispatched could still be false:
    //   a) It's not the last payload, and therefore we didn't attempt sending sendBeacon
    //   b) It's the last payload, however, we failed to queue sendBeacon call and need to now fall back to XHR.
    //      E.g. if data is over 64KB, several user agents (like Chrome) will reject to queue the sendBeacon call.
    if (dispatched === false) {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      xhr.send(JSON.stringify(payload));
    }
  }
}
