export enum FeatureFlag {
  AbstractBooklet = 'with_abstract_booklet',
  Badges = 'with_badges',
  ContentPages = 'with_content_pages',
  EnhancedContentBlocks = 'with_enhanced_content_blocks',
  MassEmails = 'with_mass_emails',
  PrivateContent = 'with_private_content',
  Reviewing = 'reviewing',
  UnlimitedSubmissions = 'with_unlimited_submissions',
  VideoRecordings = 'with_video_recordings',
  Voting = 'voting_enabled',
  WhiteLabel = 'white_label',
  WithLiveApp = 'with_live_app',
  PaymentCollection = 'with_payment_collection',
}

export interface FeatureDto {
  name: FeatureFlag;
  isEnabled: boolean;
}
