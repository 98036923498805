import moment from 'moment-timezone';

export default ({ req }, inject) => {
  const language = getLanguage(req);
  const localMoment = moment().locale(language);
  const LT = localMoment.localeData().longDateFormat('LT');
  const LTS = localMoment.localeData().longDateFormat('LTS');

  moment.updateLocale('fr', {
    monthsShort: ['janv.', 'févr.', 'mars', 'avril', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
    longDateFormat: {
      LT,
      LTS,
      L: 'D MMMM',
      l: 'Do MMMM',
      LL: 'L YYYY',
      ll: 'l YYYY',
      LLL: 'LL, LT',
      lll: 'll, LT',
      LLLL: 'LLL z',
      llll: 'lll z',
    },
  });

  moment.updateLocale('en', {
    monthsShort: ['Jan.', 'Feb.', 'March', 'April', 'May', 'June', 'July', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'],
    longDateFormat: {
      LT,
      LTS,
      L: 'MMMM D',
      l: 'MMMM Do',
      LL: 'MMMM D, YYYY',
      ll: 'l, YYYY',
      LLL: 'LL - LT',
      lll: 'll - LT',
      LLLL: 'LLL z',
      llll: 'lll z',
    },
  });

  inject('moment', moment);
};

function getLanguage(request: any) {
  if (process.client) return navigator.language;

  const acceptLanguage = request?.headers?.['accept-language'];
  if (acceptLanguage) {
    const firstAcceptLanguage = acceptLanguage.replace(/\s/g, '').split(/[;,]/)[0];
    return firstAcceptLanguage === '*' ? 'en' : firstAcceptLanguage;
  }

  return 'en';
}
