
import { Component, Emit, Model, Prop, Ref, Vue, Watch } from 'nuxt-property-decorator';
import { Editor, EditorContent, Extensions } from '@tiptap/vue-2';
import { TiptapExtension } from '../../types';
import TiptapMenu from './TiptapMenu.vue';
import TiptapLinkBubble from './TiptapLinkBubble.vue';

/* Extensions */
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Placeholder from '@tiptap/extension-placeholder';
import CharacterCount from '@tiptap/extension-character-count';
import Selection from '@fourwaves/tiptap-extension-selection-decoration';
import { Youtube, Vimeo } from '@fourwaves/tiptap-extension-video';
import LegacyVideo from './legacy-video-plugin';

@Component({
  components: {
    Editor,
    EditorContent,
    TiptapMenu,
    TiptapLinkBubble,
  },
})
export default class TiptapEditor extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly extensions!: TiptapExtension[];
  @Prop(String) readonly placeholder!: string;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly autofocus!: boolean;

  @Ref() tiptapEditor?: HTMLDivElement;

  @Model('input', { type: String, default: String }) readonly value!: string;

  readonly TiptapExtension = TiptapExtension;

  editor: Editor | null = null;

  @Emit() input(_value: string) {}
  @Emit() blur() {}
  @Emit() focus() {}
  @Emit() ready() {}
  @Emit() keydown(_event: KeyboardEvent) {}

  @Watch('value')
  onValueChange(value: string) {
    if (this.editor?.getHTML() === value) return;
    this.editor?.commands.setContent(value, false);
  }

  // This ensures that the editor's state is reactive to changes in the 'disabled' prop.
  @Watch('disabled')
  onDisabledChange(disabled: boolean) {
    this.editor?.setOptions({ editable: !disabled });
  }

  get content() {
    if (!this.editor) return '';
    return this.editor.isEmpty ? '' : this.editor.getHTML();
  }

  mounted() {
    this.editor = new Editor({
      content: this.value,
      autofocus: this.autofocus,
      editable: !this.disabled,
      onUpdate: () => this.input(this.content),
      onBlur: () => this.blur(),
      onFocus: () => this.focus(),
      onCreate: ({ editor }) => {
        editor.view.dom.classList.add('tiptap-editor__tiptap', 'rte');

        editor.view.dom.addEventListener('keydown', event => {
          this.keydown(event);
        });

        this.ready();
      },
      extensions: this.buildExtensions(),
    });
  }

  public isOptionEnabled(option: TiptapExtension) {
    return this.extensions?.includes(option) ? undefined : false;
  }

  public isExtensionEnabled(extension: TiptapExtension) {
    return this.extensions?.includes(extension);
  }

  public buildExtensions() {
    const extensions: Extensions = [
      Selection,
      StarterKit.configure({
        bold: this.isOptionEnabled(TiptapExtension.Bold),
        italic: this.isOptionEnabled(TiptapExtension.Italic),
        heading: this.isOptionEnabled(TiptapExtension.Heading) === undefined ? { levels: [1, 2, 3, 4] } : false,
        bulletList: this.isOptionEnabled(TiptapExtension.BulletList),
        orderedList: this.isOptionEnabled(TiptapExtension.OrderedList),
        code: false,
        codeBlock: false,
      }),
      Placeholder.configure({ placeholder: this.placeholder }),
    ];

    if (this.isExtensionEnabled(TiptapExtension.Underline)) {
      extensions.push(Underline);
    }

    if (this.isExtensionEnabled(TiptapExtension.Subscript)) {
      extensions.push(Subscript);
    }

    if (this.isExtensionEnabled(TiptapExtension.Superscript)) {
      extensions.push(Superscript);
    }

    if (this.isExtensionEnabled(TiptapExtension.Link)) {
      extensions.push(
        Link.configure({
          HTMLAttributes: {
            target: '_blank',
            rel: 'noopener noreferrer',
          },
          openOnClick: 'whenNotEditable',
        }),
      );
    }

    if (this.isExtensionEnabled(TiptapExtension.TextAlign)) {
      extensions.push(
        TextAlign.configure({
          types: ['heading', 'paragraph'],
          alignments: ['left', 'center'],
        }),
      );
    }

    if (this.isExtensionEnabled(TiptapExtension.Video)) {
      extensions.push(Youtube);
      extensions.push(Vimeo);
      extensions.push(LegacyVideo);
    }

    if (this.isExtensionEnabled(TiptapExtension.WordCount)) {
      extensions.push(CharacterCount);
    }

    return extensions;
  }

  beforeDestroy() {
    if (!this.tiptapEditor) return;

    /* Keeps the editor visible until page transition is over */
    const content = this.tiptapEditor.innerHTML;

    /* Remove src attribute from iframe elements to prevent them to reload */
    const strippedContent = content.replace(/<iframe[^>]*\s+src="[^"]*"[^>]*>/gi, match => {
      return match.replace(/\s+src="[^"]*"/i, '');
    });

    this.tiptapEditor.innerHTML = strippedContent;

    this.editor?.destroy();
  }
}
