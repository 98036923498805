import { BillingInfoDto } from './../BillingInfoDto';
import { CardType, PaymentMethod, TransactionStatus } from './../TransactionDto';
import { TranslatableField } from '../TranslatableField';
import { UserDto } from './../UserDto';
import { OrganizationCurrency } from './../OrganizationDto';
import { UpgradeItemDto } from '../UpgradePricing';
import { Color } from '../../../../types/design-system';

export enum UpgradeTransactionState {
  PaymentPending = 'paymentpending',
  PaymentMissing = 'paymentmissing',
  Overdue = 'overdue',
  Canceled = 'canceled',
  Paid = 'paid',
}

export const UpgradeTransactionStateColorMap = {
  [UpgradeTransactionState.Overdue]: Color.Error,
  [UpgradeTransactionState.PaymentPending]: Color.Warning,
  [UpgradeTransactionState.PaymentMissing]: Color.Warning,
  [UpgradeTransactionState.Canceled]: Color.Gray,
  [UpgradeTransactionState.Paid]: Color.Success,
};

export interface UpgradeTransactionDto {
  id: string;
  invoiceNumber: string;
  items: UpgradeItemDto[];
  organizationName: TranslatableField;
  status: TransactionStatus;
  state: UpgradeTransactionState;
  paymentMethod: PaymentMethod;
  currency: OrganizationCurrency;
  gst: number;
  qst: number;
  hst: number;
  subTotal: number;
  total: number;
  createdBy: UserDto | null;
  billingInfo: BillingInfoDto;
  nameOnCard: string | null;
  cardType: CardType | null;
  last4Digits: string | null;
  purchaseOrderNumber: string | null;
  dueDate: string;
  gracePeriodEndDate: string;
  maxActiveEvents: number;
  termLengthInYears: number;
  creationDate: string;
}
