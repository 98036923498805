import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class ModalMixin extends Vue {
  @Prop({ type: String, default: null }) readonly actionLabel!: string;
  @Prop({ type: String, default: null }) readonly actionIcon!: string;
  @Prop({ type: String, default: null }) readonly cancelLabel!: string;
  @Prop({ type: String, default: null }) readonly title!: string | null;
  @Prop({ type: String, default: null }) readonly subtitle!: string | null;
  @Prop(Boolean) readonly disableClickOutside!: boolean;
  @Prop(Boolean) readonly actionLess!: boolean;
  @Prop(Boolean) readonly isWaiting!: boolean;
  @Prop(Boolean) readonly highlight!: boolean;

  showModal: boolean = false;
  callBackFocusElement: HTMLButtonElement | null = null;

  get mainActionLabel() {
    return this.actionLabel || this.$t('shared.modal.save');
  }

  get secondaryActionLabel() {
    return this.cancelLabel || this.$t('shared.modal.cancel');
  }

  protected save(event: PointerEvent | SubmitEvent) {
    event.preventDefault();
    this.$emit('save');
  }

  public open(targetElement?: HTMLButtonElement | EventTarget | null) {
    if (targetElement instanceof HTMLButtonElement) this.callBackFocusElement = targetElement;
    this.showModal = true;
  }

  public close() {
    this.showModal = false;
  }

  public onClickOutside() {
    if (this.disableClickOutside || this.isWaiting) return;
    this.close();
  }

  /**
   * @deprecated Use `open` or `close` function instead of this method.
   */
  public triggerVisibility(targetElement?: HTMLButtonElement | EventTarget | null) {
    if (targetElement instanceof HTMLButtonElement) this.callBackFocusElement = targetElement;
    this.showModal = !this.showModal;
  }
}
