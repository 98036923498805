// source: https://stackoverflow.com/a/46192691
export function normalizeDiacritics(str: string) {
  return str.normalize('NFKD').replace(/[^\w\s.!?@#$%&*()_+-=]/g, '');
}

export function getWordCount(text: string) {
  return text
    .toString()
    .split(/\s+/)
    .filter(word => /\w+/.test(word)).length;
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
