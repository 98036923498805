
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import Tooltip from './Tooltip.vue';

@Component({
  components: {
    Tooltip,
  },
})
export default class Hint extends Vue {
  @Prop({ type: String }) content!: string;
  @Prop(Boolean) readonly disabled!: boolean;
}
